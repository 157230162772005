import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";


i18n.use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    whiteList: ["en", "ru", "arm"],
    debug: false,
    ns:['translation'],
    defaultNS: ['translation'],
    detection: {
      order: ["localStorage", "cookie"],
      caches: ["localStorage", "cookie"],
    },
    loadPath: '/locales/{{lng}}/{{ns}}.json',
    interpolation: {
      escapeValue: false,
    },
  });


export default i18n;