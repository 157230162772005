import {isMobile} from "react-device-detect";

import img1 from "../../../assets/images/yourRidingBuddy/first.png";
import img2 from "../../../assets/images/yourRidingBuddy/second.png";
import img3 from "../../../assets/images/yourRidingBuddy/third.png";
import img4 from "../../../assets/images/yourRidingBuddy/fourth.png";

import css from "./yourRidingBuddy.module.scss";
import {useTranslation} from "react-i18next";

const YourRidingBuddy = () => {
  const { t } = useTranslation();
  const classes =
    css.yourRidingBuddyContainer +
    (isMobile ? " " + css.mobileYourRidingBuddyContainer : "");

  return (
    <div className={classes} id="your-riding-buddy">
      <div className={css.informationContainer}>
        <h1>{t('yourRidingBuddyTitle')}</h1>
        <p>
          {t('yourRidingBuddyText')}
        </p>
      </div>
      <div className={css.container}>
        <div className={css.item}>
          <img src={img1} alt=""/>
          <h3>{t('healthyLifestyle')}</h3>
          <p>
            {t('healthyLifestyleText')}
          </p>
        </div>
        <div className={css.item}>
          <img src={img2} alt=""/>
          <h3>{t('reduceTraffic')}</h3>
          <p>
            {t('reduceTrafficText')}
          </p>
        </div>
        <div className={css.item}>
          <img src={img3} alt=""/>
          <h3>{t('protectEnvironment')}</h3>
          <p>
            {t('protectEnvironmentText')}
          </p>
        </div>
        <div className={css.item}>
          <img src={img4} alt=""/>
          <h3> {t('accessible')}</h3>
          <p>
            {t('accessibleText')}
          </p>
        </div>
      </div>
    </div>
  );
};

export default YourRidingBuddy;
