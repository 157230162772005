import axios from "axios";
import {toast} from "react-toastify";
import requests from "../../services";

export const becomePartnerThunk = (data)=>async (dispatch)=>{

  try{
    const response = await axios.post(requests.partner,data)
    dispatch({
      type:'BECOME_PARTNER',
      payload:response.data
    })
    toast.success('Your email has been successfully sent', {
      position: "top-right",
      autoClose: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      duration: 5000,
      theme: "light",
    });
  }
  catch (e){
    console.log(e)

    toast.error('Your subscribe has been  failed', {
      position: "top-right",
      autoClose: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      duration: 5000,
      theme: "light",
    });
  }
}