import React from 'react';
import css from './feelTheFreedom.module.scss'
import img from "../../../assets/images/feelTheFreedom/Scooter.png";
import svg1 from "../../../assets/images/changeYourDevice/svg1.png";
import svg2 from "../../../assets/images/changeYourDevice/svg2.png";
import {isMobile} from "react-device-detect";
import {useTranslation} from "react-i18next";

const FeelTheFreedom = () => {
  const { t } = useTranslation();
  const classes = css[isMobile ? 'mobileContainer' : 'container'];

  return (
    <div className={classes}>
      <div className={css.infoContainer}>
        <h1>
          {
            t(`scooterTitle`)
          }
        </h1>
        <p>
          {
            t(`scooterText`)
          }
        </p>
        <div className={css.btnContainer}>
          <div className={css.button}>
            <a href="https://apps.apple.com/am/app/mimo-meta-sharing/id1576701754" target={"_blank"}>
            <div>
              <img src={svg1} alt=""/>
            </div>
            <div>
              <p>{t(`Download on the`)}</p>
              <h5>App Store</h5>
            </div>
            </a>
          </div>
          <div className={css.button}>
            <a href="https://play.google.com/store/apps/details?id=app.mimo.it.MimoBike" target={"_blank"}>

            <div>
              <img src={svg2} alt=""/>
            </div>
            <div>
              <p>{t(`Get it on`)}</p>
              <h5>Google Play</h5>
            </div>
            </a>
          </div>
        </div>
      </div>
      <div className={css.imgContainer}>
        <img src={img} alt=""/>
      </div>
    </div>
  );
};

export default FeelTheFreedom;