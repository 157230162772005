import Video from "../powerBankSharing/video";
import ContactUs from "../powerBankSharing/contactUs";
import DownloadOurApp from "../home/downloadOurApp";
import FeelTheFreedomEnjoyTheRide from "./feeltheFreedomEnjoytheRide";
import HowToRent from "../scooterSharing/howToRent";
import BikeTariffs from "./bikeTariffs";
import BikePackages from "./bikePackages";
import img from "../../assets/images/earnMoney/Contact.png";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {getDataThunk} from "../../redux/action/data";
import css from "./bikeSharing.module.scss"

const BikeSharing = () => {

  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.mimoDataReducer);

  const bikeSharingTariffs = data?.data?.bikeSharingTariffs;
  const bikeSharingPlansData = data?.data?.bikeSharingPlansData;


  useEffect(() => {
    dispatch(getDataThunk());
  }, []);
  return (
    <div className={css.container}>
      <FeelTheFreedomEnjoyTheRide/>
      <HowToRent/>
      <DownloadOurApp/>
      <Video/>
      <BikeTariffs bikeSharingTariffs={bikeSharingTariffs} />
      <BikePackages bikeSharingPlansData={bikeSharingPlansData}/>
      <ContactUs img={img}/>
    </div>
  );
};

export default BikeSharing;