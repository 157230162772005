import React, {useEffect, useState} from 'react';
import {Link, useLocation} from "react-router-dom";
import { CiFacebook, CiLinkedin, CiMail, CiInstagram} from "react-icons/ci";
import {BsTelephone} from "react-icons/bs";
import {isMobile} from "react-device-detect";

import Logo from "../../pages/logo";
import {AuthRoutes} from "../../pages/router";

import { HOME_PAGE, OUR_SERVICES_PAGE} from '../../utils/url';

import css from "../footer/footer.module.scss";
import {useDispatch, useSelector} from "react-redux";
import {subscribeThunk} from "../../redux/action/subscribe";
import {getDataThunk} from "../../redux/action/data";


const Footer = () => {

  const dispatch = useDispatch()
  const date = new Date().getFullYear()
  const {pathname} = useLocation();
  const {data} = useSelector((state) => state.mimoDataReducer);
  const contactUs = data?.data?.contactUs
  let classes = css.footerContainerDark + ( " " + (isMobile ? css.mobileFooter : css.desktopFooter));


  const [inputValues, setInputValues] = useState({
    name: 'subscribe',
    email: '',
    country: 'subscribe',
    phone: 'subscribe',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputValues({ ...inputValues, [name]: value });
  };
  const handleAdd =() =>{
    const { name, email, country, phone } = inputValues;

    if (name && email && country && phone) {

      dispatch(subscribeThunk(inputValues)).then(  dispatch({
        type:'LOADING'
      })).then(_=>{
        setInputValues({
          name: 'subscribe',
          email: '',
          phone: 'subscribe',
          country: 'subscribe',
        });
      })
    }
  }

  if (pathname === HOME_PAGE) {
    classes += " " + css.footerContainerDark;
  } else if (pathname === OUR_SERVICES_PAGE) {
    classes += " " + css.footerContainerDark;
  } else {
    classes += " " + css.footerContainerLight;

  }
  useEffect(() => {
    dispatch(getDataThunk());
  }, []);
  return (

    <div>
      <footer className={classes} data-testid="footer">
        <div className={css.subFooter}>
          {contactUs &&
          <div className={css.subFooterInfo}>
            <Logo/>

              <a href={contactUs.phone}><BsTelephone/> {contactUs.phone}</a>
              <a ><CiMail/>{contactUs.email}</a>

          </div>
          }
          <div className={css.subFooterSubscribe}>
            <h4>Subscribe to our newsletter</h4>
            <div>
              <input
                name="email"
                value={inputValues.email}
                onChange={handleInputChange}
                type="email"
                placeholder="Enter your email address"/>
              <button onClick={handleAdd} >Subscribe</button>
            </div>

          </div>
        </div>
        <ul className={css.footerMenuContainer}>
          {AuthRoutes.map(({name,path}) => {
            return  <li key={path}>
              <Link key={path} to={path}>{name}</Link>
            </li>
          })}
        </ul>
        <div className={css.socialPages}>
          <a href="https://www.facebook.com/mimobikesharing?mibextid=LQQJ4d" target={"blank"}><CiFacebook/></a>
          <a href="https://www.linkedin.com/company/mimo-meta-sharing/" target={"blank"}><CiLinkedin/></a>
          <a href="https://www.instagram.com/mimo_meta_sharing?igshid=ODA1NTc5OTg5Nw==" target={"blank"}><CiInstagram /></a>
        </div>
        <div className={css.copyrightContainer}>
          &copy; Copyright {date} Mimo All rights reserved.
        </div>
      </footer>
    </div>
  );
};

export default Footer;