import {useEffect, useState, } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";

import Logo from "../logo";
import { MobileNavigation } from "../../components/mobileNavigation/mobileNavigation";

import {
  BECOME_A_PARTNER,
  BIKE_SHARING,
  CONTACT_PAGE,
  HOME_PAGE,
  OUR_SERVICES_PAGE,
  POWER_BANK_SHARING,
  SCOOTER_SHARING,
} from "../../utils/url";

import arrowIcon from "../../assets/images/header/arrow.png";
import menuIcon from "../../assets/images/header/menu.svg";

import css from "./menu.module.scss";
import {useDispatch, useSelector} from "react-redux";
import {getDataThunk} from "../../redux/action/data";
import LangSelect from "../../components/languageSelect/languageSelect";
import {useTranslation} from "react-i18next";

const options = [
  {
    name: "Bike Sharing",
    value: BIKE_SHARING,
  },
  {
    name: "Scooter Sharing",
    value: SCOOTER_SHARING,
  },
  {
    name: "Power Bank Sharing",
    value: POWER_BANK_SHARING,
  },
];

const Menu = () => {
  const navigateTo = useNavigate();
  const {pathname} = useLocation();
  const { t } = useTranslation();
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [isMobileNavigationOpen, setIsMobileNavigationOpen] = useState(false);
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.mimoDataReducer);

  const text = data?.data?.text?.text

  const selectPage = (e, option) => {
    e.preventDefault();
    navigateTo(option.value);
  };

  const handleMouseEnter = () => {
    setDropdownVisible(true);
  };

  const handleMouseLeave = () => {
    setDropdownVisible(false);
  };

  const navigateToHomePage = (path) => {
    if(pathname === path) {
      const scrollTo = document.getElementById("your-riding-buddy")?.offsetTop;
      window.scrollTo({ top: scrollTo, behavior: 'smooth'});
    } else {
      navigateTo(path);
    }
  }
  useEffect(() => {
    dispatch(getDataThunk());
  }, []);

  const classes = css[isMobile ? "mobileMenu" : "menu"];

  return (
    <div className={classes}>
      {isMobile ? (
      <div className={css.mobileContainer}>
        <div className={css.textContainer}>
          <p>{text}</p>
        </div>
        <div className={css.mobileHeader}>
          <Logo />
          <img
            src={menuIcon}
            alt="menu"
            onClick={() => setIsMobileNavigationOpen(true)}
          />
          {/*<LangSelect/>*/}
        </div>

      </div>
      ) : (
        <>
    <div className={css.container}>
      <div className={css.textContainer}>
        <p>{text}</p>
      </div>
      <div className={css.menuContainer}>
        <ul className={css.ul}>
          <Link to={HOME_PAGE}>
            <Logo/>
          </Link>
          <li onClick={() => navigateToHomePage(HOME_PAGE)}>
            {t('aboutMimo')}
          </li>
          <li className="menu">
            <Link to={OUR_SERVICES_PAGE}  onMouseEnter={handleMouseEnter}>
              <span >{t('ourSharingServices')}</span>
              <img src={arrowIcon} alt=""  />
              {isDropdownVisible ? (
                <div
                  className={css["dropdown-menu"]}
                  onMouseLeave={handleMouseLeave}
                >
                  <ul>
                    {options.map((option) => (
                      <li
                        key={Math.random()}
                        value={option.value}
                        onClick={(e) => selectPage(e, option)}
                      >
                        {option.name}
                      </li>
                    ))}
                  </ul>
                </div>
              ) : null}
            </Link>
          </li>
          <li>
            <Link to={BECOME_A_PARTNER}>{t('becomeAPartner')}</Link>
          </li>
        </ul>
        <div className={css.contactContainer}>
          <Link to={CONTACT_PAGE}>
            <button>{t('contact')}</button>
          </Link>
        </div>
        {/*<LangSelect/>*/}
      </div>
    </div>
        </>
      )}
      {isMobileNavigationOpen ? (
        <MobileNavigation onClose={() => setIsMobileNavigationOpen(false)} />
      ) : null}
    </div>
  );
};

export default Menu;
