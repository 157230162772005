import React, {useState} from 'react';
import css from "./howToBecomePartner.module.scss"
import {isMobile} from "react-device-detect";
import Modal from "../../../components/modal";
import {IoCloseSharp} from "react-icons/io5";
import ModalForm from "../../../components/modalForm";
import {useTranslation} from "react-i18next";

const HowToBecomePartner = () => {
  const { t } = useTranslation();

  const[openModal,setOpenModal] = useState(false);

  const handleClose = ()=>{
    setOpenModal(false)
  }
  const classes = css[isMobile ? 'mobileContactContainer' : 'contactContainer'];
  return (
    <div className={classes}>
      {
        openModal ? <Modal>
          <div className={css.sendFormModal}>
            <div className={css.modalTitle}>
              <div className={css.closeContainer}>
                <IoCloseSharp onClick={handleClose}/>
              </div>
              <h2>Become a Partner</h2>
            </div>
            <ModalForm/>
          </div>
        </Modal> : ''
      }
      <h1>{t('howToBecomeAPartner')}</h1>
      <div className={css.infoContainer}>
        <div className={css.items}>
          <div className={css.item}>
            <div className={css.itemNumber}>1</div>
            <div>
              <p>
                {t('howToBecomeAPartnerItem1')}
              </p>
            </div>
          </div>
          <div className={css.item}>
            <div className={css.itemNumber}>2</div>
            <div>
              <p>
                {t('howToBecomeAPartnerItem2')}
              </p>
            </div>
          </div>
          <div className={css.item}>
            <div className={css.itemNumber}>3</div>
            <div>
              <p>
                {t('howToBecomeAPartnerItem3')}
              </p>
            </div>
          </div>
        </div>
        <div className={css.items}>
          <div className={css.item}>
            <div className={css.itemNumber}>4</div>
            <div>
              <p>
                {t('howToBecomeAPartnerItem4')}
              </p>
            </div>
          </div>
          <div className={css.item}>
            <div className={css.itemNumber}>5</div>
            <div>
              <p>
                {t('howToBecomeAPartnerItem5')}
              </p>
            </div>
          </div>
          <div className={css.item}>
            <div className={css.itemNumber}>6</div>
            <div>
              <p>
                {t('howToBecomeAPartnerItem6')}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={css.btnContainer}>
        <button onClick={()=>{setOpenModal(true)}}>{t(`becomeAPartner`)}</button>
      </div>
    </div>
  );
};

export default HowToBecomePartner;