import { isMobile } from "react-device-detect";

import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";

import { Swiper, SwiperSlide } from "swiper/react";

import Review from "../review";

import { data } from "../data";

import arrowIcon from "../../../assets/images/header/arrow.png";

import css from "./whatOurRidersSay.module.scss";

import "swiper/css";
import {useTranslation} from "react-i18next";

const WhatOurRidersSay = () => {
  const { t } = useTranslation();
  const scrollLeft = () => {
    const swiperEl = document?.querySelector(".swiper")?.swiper;
    swiperEl.slidePrev();
  };

  const scrollRight = () => {
    const swiperEl = document?.querySelector(".swiper")?.swiper;
    swiperEl?.slideNext();
  };

  const classes =
    css.whatOurRidersSay + (isMobile ? " " + css.mobileWhatOurRidersSay : "");

  return (
    <div className={classes}>
      <div className={css.subWhatOurRidersSay}>
        <div className={css.section1} id={"1"}>
          <Swiper
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            spaceBetween={50}
            slidesPerView={1}
            navigation
            scrollbar={{ draggable: true }}
          >
            {data.map(({  text }) => {
              return (
                <SwiperSlide key={Math.random()}>
                  <Review   text={text} />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
        <div className={css.section2}>
          <h1>{t("whatOurRidersSay")}</h1>
          <p>
            {t("whatOurRidersSayText")}{" "}
          </p>
        </div>
      </div>
      <div className={css.navigationButtons}>
        <div onClick={scrollRight} className={css.arrowContainer}>
          <img src={arrowIcon}  />
        </div>
        <div onClick={scrollLeft} className={css.arrowContainer}>
          <img src={arrowIcon}  />
        </div>
      </div>
    </div>
  );
};

export default WhatOurRidersSay;
