import colons from "../../../assets/images/review/colons.png";
import avatar from "../../../assets/images/review/avatar.png";

import css from "./review.module.scss";
import {isMobile} from "react-device-detect";
import {useTranslation} from "react-i18next";

const Review = ({name,text}) => {
  const { t } = useTranslation();
  const classes =
    css.review + (isMobile ? " " + css.mobileWhatOurRidersSay : "");

  return (
    <div className={css.review}>
      <img src={colons} alt="" />
      <div className={css.text}>
        {t(text)}
      </div>

    </div>
  );
};

export default Review;
