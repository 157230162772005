const initialStat= {
  data:null
}
export const subscribeReducer = (state=initialStat,action)=>{
  switch (action.type) {
    case "SUBSCRIBE":
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
}