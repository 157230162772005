import { isMobile } from "react-device-detect";

import aboutMimoImage from "../../../assets/images/aboutMimo/aboutMimo.png";
import bike from "../../../assets/images/aboutMimo/bike.png";
import scooter from "../../../assets/images/aboutMimo/Group 1333.png";
import charger from "../../../assets/images/aboutMimo/charger.png";

import css from "./aboutMimo.module.scss";
import React, {useState} from "react";
import Modal from "../../../components/modal";
import {IoCloseSharp} from "react-icons/io5";
import ModalForm from "../../../components/modalForm";
import {useTranslation} from "react-i18next";

const AboutMimo = () => {
  const userAgent = navigator.userAgent;
  const isAndroid = userAgent.indexOf('Android') > -1;
  const isiOS = /iPhone|iPad|iPod/.test(userAgent);

  const androidURL = 'https://play.google.com/store/apps/details?id=app.mimo.it.MimoBike';
  const iOSURL = 'https://apps.apple.com/am/app/mimo-meta-sharing/id1576701754';

  const handleClick = () => {
    if (isAndroid) {
      window.location.href = androidURL;
    } else if (isiOS) {
      window.location.href = iOSURL;
    } else {
      window.location.href = 'https://play.google.com/store/apps/details?id=app.mimo.it.MimoBike';
    }
  };
  const { t } = useTranslation();
  const[openModal,setOpenModal] = useState(false)
  const handleClose = ()=>{
    setOpenModal(false)
  }
  const classes =
    css.aboutMimoContainer +
    (isMobile ? " " + css.mobileAboutMimoContainer : "");

  return (
    <div className={classes}>

      <div className={css.contentContainer}>
        <div className={css.informationContainer}>
          <h1>{t('aboutMimoTitle')}</h1>
          <p>
            {t('aboutMimoText')}
          </p>
          <div className={css.buttonContainer}>
            <button onClick={handleClick}>
              <a href="https://play.google.com/store/apps/details?id=app.mimo.it.MimoBike" target={"_blank"}>{t('downloadApp')}</a>
            </button>
            <button onClick={()=>{setOpenModal(true)}}>{t('becomePartner')}</button>
          </div>
        </div>
        <div className={css.imageContainer}>
          <img src={aboutMimoImage} />
        </div>
      </div>
      <div className={css.footerContainer}>
        <div className={css.container}>
          <div className={css.footerInformationContainer}>
            <h1>{t('AnimationTitle')}</h1>
            <p>
              {t('AnimationText')}
            </p>
          </div>
          <div className={css.animationContainer}>
            <div className={css.item}>
              <img src={scooter} alt="" />
              <p>Scooter</p>
            </div>
            <div className={css.item}>
              <img src={bike} alt="" />
              <p>Bike</p>
            </div>
            <div className={css.item}>
              <img src={charger} alt="" />
              <p>Charger</p>
            </div>
          </div>
        </div>
      </div>
      {
        openModal ? <Modal>
          <div className={css.sendFormModal}>
            <div className={css.modalTitle}>
              <div className={css.closeContainer}>
                <IoCloseSharp onClick={handleClose}/>
              </div>
              <h2>Become a Partner</h2>
            </div>
            <ModalForm/>
          </div>
        </Modal> : ''
      }
    </div>
  );
};

export default AboutMimo;
