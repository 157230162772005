import {isMobile} from "react-device-detect";

import Logo from "../../logo";

import css from "./earnMoney.module.scss";
import {useDispatch} from "react-redux";
import {useState} from "react";
import {contactUsThunk} from "../../../redux/action/contactUs";
import {useTranslation} from "react-i18next";
import {becomePartnerThunk} from "../../../redux/action/becomePartner";


const EarnMoney = ({img}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch()


  const [inputValues, setInputValues] = useState({
    name: '',
    email: '',
    country: '',
    phone: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputValues({ ...inputValues, [name]: value });
  };
  const handleSend=() =>{
    const { name, email, country, phone } = inputValues;

    if (name && email && country && phone) {

      dispatch(becomePartnerThunk(inputValues)).then(
        setInputValues({
          name: '',
          email: '',
          country: '',
          phone: '',
        })
      )
    }
  }
  const classes = css[isMobile ? 'mobileContainer' : 'container'];
  return (
    <div className={classes}>
        <div className={css.section}>
          <div className={css.imgContainer}>
            <img src={img} />
          </div>
         <div  className={css.earnMoneyForm} >
           <div className={css.titleContainer}>
             <h1>
               {t('earnMoneyWith')}
             </h1>
             <Logo/>
           </div>
           <div className={css.form}>
             <div className={css.sendForm}>
               <div className={css.rows}>
                 <div className={css.firstRowItems}>
                   <input
                     onChange={handleInputChange}
                     name='name'
                     type="text"
                     placeholder={t('namePlaceholder')}
                     value={inputValues.name}
                   />
                   <input
                     onChange={handleInputChange}
                     name='email'
                     type="text"
                     placeholder={t('emailPlaceholder')}
                      value={inputValues.email}
                   />
                 </div>
                 <div className={css.rowItems}>
                   <input
                     onChange={handleInputChange}
                     name='country'
                     type="text"
                     placeholder={t('Country')}
                     value={inputValues.country}
                   />
                   <input
                     onChange={handleInputChange}
                     name="phone"
                     cols="10"
                     placeholder={t('Phone')}
                     value={inputValues.phone}  >

                   </input>
                   <div className={css.btnContainer}>
                     <button onClick={handleSend} >
                       {t('getABusinessPlan')}
                     </button>
                   </div>

                 </div>
               </div>

             </div>
           </div>
         </div>
        </div>
    </div>
  );
};

export default EarnMoney;