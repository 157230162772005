import {
  BECOME_A_PARTNER,
  BIKE_SHARING,
  CONTACT_PAGE,
  HOME_PAGE,
  OUR_SERVICES_PAGE,
  POWER_BANK_SHARING,
  SCOOTER_SHARING
} from "../utils/url";
import Homepage from "./home";
import Contact from "./contact";
import BecomeAPartner from "./becomeAPartner";
import BikeSharing from "./bikeSharing";
import ScooterSharing from "./scooterSharing";
import PowerBankSharing from "./powerBankSharing";

export const AuthRoutes = [

  {
    name: 'About mimo',
    path: HOME_PAGE,
    element: <Homepage/>
  },

  {
    name: 'Our Sharing Services',
    path: OUR_SERVICES_PAGE,
    element: <Homepage/>
  },
  {
    name: 'Become a Partner',
    path: BECOME_A_PARTNER,
    element: <BecomeAPartner/>
  },
  {
    name: 'Contact',
    path: CONTACT_PAGE,
    element: <Contact/>,
    type:'button'
  },
  {
    name:'Bike Sharing',
    path: BIKE_SHARING,
    element: <BikeSharing/>,
    subMenu:true,
  },
  {
    name:'Scooter Sharing',
    path: SCOOTER_SHARING,
    element: <ScooterSharing/>,
    subMenu:true,
  },
  {
    name:'Power Bank Sharing',
    path: POWER_BANK_SHARING,
    element: <PowerBankSharing/>,
    subMenu:true,
  }
]