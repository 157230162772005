import React, { useEffect, useState } from 'react';
import css from './bikePackages.module.scss';
import { isMobile } from 'react-device-detect';
import {useTranslation} from "react-i18next";

const BikePackages = ({ bikeSharingPlansData }) => {
  const { t } = useTranslation();
  const [selectedCountry, setSelectedCountry] = useState('');

  useEffect(() => {
    if (bikeSharingPlansData && Object.keys(bikeSharingPlansData).length > 0) {
      setSelectedCountry(Object.keys(bikeSharingPlansData)[0]);
    }
  }, [bikeSharingPlansData]);

  const handleCountryChange = (e) => {
    setSelectedCountry(e.target.value);
  };

  const isValidData = (data) => {
    return data?.name !== '' && data?.price !== '' && data?.text !== '' && data?.price !== 0;
  };

  const renderDottedLine = (index, totalItems) => {
    return index !== totalItems - 1 ? <div className={css.dotted}></div> : null;
  };

  const classes = css[isMobile ? 'mobileContainer' : 'container'];

  return (
    <div className={classes}>
      <div className={css.leftContainer}>
        <h1>{t(`bikePackages`)}</h1>
        <p>
          {
            t(`bikePackagesText`)
          }
        </p>
      </div>
      <div className={css.rightContainer}>
        <select value={selectedCountry} onChange={handleCountryChange}>
          {bikeSharingPlansData &&
            Object.keys(bikeSharingPlansData).map((country) => (
              <option key={country} value={country}>
                {country}
              </option>
            ))}
        </select>
        {selectedCountry &&
          bikeSharingPlansData[selectedCountry]?.map(({ name, price, text }, index, array) => (

            <>    {isValidData({name, price, text}) ? (
              <>
                <div key={index} className={css.item}>
                  <div className={css.itemTitle}>
                    <p>{name}</p>
                    <p>{price}</p>
                  </div>
                  <p>{text}</p>

                </div>
                {renderDottedLine(index, array.length)}
              </>
            ) : (
              <p >
                Coming soon ...
              </p>
            )}
            </>

          ))}
      </div>
    </div>
  );
};

export default BikePackages;
