export const data = [{
  name: 'John Doe',
  text: 'text1'
},
  {
    name: 'John Doe',
    text: 'text2'
  }, {
    name: 'John Doe',
    text: 'text3'
  }, {
    name: 'John Doe',
    text: 'text4'
  }, {
    name: 'John Doe',
    text: 'text5'
  }, {
    name: 'John Doe',
    text: 'text6'
  }
  , {
    name: 'John Doe',
    text: 'text7'
  },
  {
    name: 'John Doe',
    text: 'text8'
  }, {
    name: 'John Doe',
    text: 'text9'
  }

]

