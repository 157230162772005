import React, {useEffect, useState} from 'react';
import css from './scooterTariffs.module.scss'
import {isMobile} from "react-device-detect";


const ScooterTariffs = ({scooterSharingTariffs}) => {

  const [selectedCountry, setSelectedCountry] = useState('');

  useEffect(() => {
    if (scooterSharingTariffs && Object.keys(scooterSharingTariffs).length > 0) {
      setSelectedCountry(Object.keys(scooterSharingTariffs)[0]);
    }
  }, [scooterSharingTariffs]);

  const handleCountryChange = (e) => {
    setSelectedCountry(e.target.value);
  };

  const isValidData = (data) => {
    return (
      data?.name !== '' &&
      data?.price !== '' &&
      data?.text !== '' &&
      data?.price !== 0
    );
  };
  const classes = css[isMobile ? 'mobileContainer' : 'container']
  return (
    <div className={classes}>
      <h1>Scooter Tariffs</h1>
      <select value={selectedCountry} onChange={handleCountryChange}>
        {scooterSharingTariffs &&
          Object.keys(scooterSharingTariffs).map((country) => (
            <option key={country} value={country}>
              {country}
            </option>
          ))}
      </select>
      <div className={css.items}>
        {selectedCountry &&
          (scooterSharingTariffs[selectedCountry]?.length > 0 ? (
            scooterSharingTariffs[selectedCountry].map(({name, price, text}, index) => (
              <>
                {isValidData({name, price, text}) ? (
                  <div className={css.item}>
                    <div className={css.numberContainer}>{name}</div>
                    <h3>{text}</h3>
                    <p>{price}</p>
                  </div>
                ) : (
                  <p>
                    Coming soon ...
                  </p>
                )}
              </>
            ))
          ) : (
            <p>''</p>
          ))}

      </div>
    </div>
  );
};

export default ScooterTariffs;