import React from 'react';
import css from './howToRent.module.scss'
import {isMobile} from "react-device-detect";
import img1 from "../../../assets/images/howToRent/first.png";
import img2 from "../../../assets/images/howToRent/second.png";
import img3 from "../../../assets/images/easyToUse/Frame.png"
import img4 from "../../../assets/images/howToRent/four-2.png";
import img5 from "../../../assets/images/howToRent/five.png";
import {useTranslation} from "react-i18next";

const HowToRent = () => {
  const { t } = useTranslation()
  const classes = css[isMobile ? 'mobileContainer' : 'container'];

  return (
    <div className={classes}>
      <div className={css.titleContainer} >
        <h1>{t(`secondSectionItem1`)}</h1>

      </div>
      <div className={css.itemContainerRight}>
        <img src={img1} />
        <div className={css.informationContainer}>
          <div className={css.orderContainer}>1</div>
          <div className={css.subInformationContainer} >
            <h3>{t(`secondSectionItem2`)}</h3>
           </div>
        </div>
      </div>
      <div className={css.itemContainerLeft}>
        <div className={css.informationContainer}>
          <div className={css.orderContainer}>2</div>
          <div className={css.subInformationContainer}>
            <h3>{t(`secondSectionItem3`)}</h3>
           </div>
        </div>
        <img src={img2} alt=""/>
      </div>
      <div className={css.itemContainerRight} >
        <img src={img3} alt=""/>
        <div className={css.informationContainer}>
          <div className={css.orderContainer}>3</div>
          <div className={css.subInformationContainer}>
            <h3>{t(`secondSectionItem4`)}</h3>
            </div>
        </div>
      </div>
      <div className={css.itemContainerLeft}>
        <div className={css.informationContainer}>
          <div className={css.orderContainer}>4</div>
          <div className={css.subInformationContainer}>
            <h3>{t(`secondSectionItem5`)}</h3>
            </div>
        </div>
        <img src={img4} alt=""/>
      </div>
      <div className={css.itemContainerRight}>
        <img src={img5} alt=""/>
        <div className={css.informationContainer}>
          <div className={css.orderContainer}>5</div>
          <div className={css.subInformationContainer} >
            <h3>{t(`secondSectionItem6`)}</h3>
           </div>
        </div>
      </div>
    </div>
  );
};

export default HowToRent;