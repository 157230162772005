import React from 'react';
import img1 from '../../../assets/images/forUser/Power Bank 2.png'
import img2 from '../../../assets/images/forUser/Icon_checkmark.png'
import css from './forUser.module.scss'
import {isMobile} from "react-device-detect";
import {useTranslation} from "react-i18next";

const ForUser = () => {
  const { t } = useTranslation()
  const classes = css[isMobile?'mobileContainer':'container']
  return (
    <div className={classes}>
      <div className={css.imgContainer}>
        <img src={img1} alt=""/>
      </div>
      <div className={css.infoContainer}>
        <h1>{t('forUser')}</h1>
        <div className={css.item}>
          <img src={img2} />
          <p>{t('more convenient')}</p>
        </div>
        <div className={css.item}>
          <img src={img2} />
          <p>{t('charge on the go')}</p>
        </div>
        <div className={css.item}>
          <img src={img2} />
          <p>{t('save time and energy')}</p>
        </div>
      </div>
    </div>
  );
};

export default ForUser;