import React, {useEffect, useState} from 'react';
import css from './chargerTariffs.module.scss'
import {isMobile} from "react-device-detect";
import {useTranslation} from "react-i18next";

const ChargerTariffs = ({powerBankSharingTariffs}) => {
  const { t } = useTranslation()
  const [selectedCountry, setSelectedCountry] = useState('');

  useEffect(() => {
    if (powerBankSharingTariffs && Object.keys(powerBankSharingTariffs).length > 0) {
      setSelectedCountry(Object.keys(powerBankSharingTariffs)[0]);
    }
  }, [powerBankSharingTariffs]);

  const handleCountryChange = (e) => {
    setSelectedCountry(e.target.value);
  };
  const renderDottedLine = (index, totalItems) => {
    return index !== totalItems - 1 ? <div className={css.borderDotted}></div> : null;
  };
  const isValidData = (data) => {
    return (
      data?.name !== '' &&
      data?.price !== '' &&
      data?.text !== '' &&
      data?.price !== 0
    );
  };


  const classes = css[isMobile?'mobileChargerTariffs':'chargerTariffs']

  return (
    <div className={classes}>
      <div className={css.section1} >
        <h1>{t('chargerTariffs')}</h1>
        <p>{t('chargerTariffsText')}</p>
      </div>

      <div className={css.section2}>
        <select value={selectedCountry} style={{height:50}} onChange={handleCountryChange}>
          {powerBankSharingTariffs &&
            Object.keys(powerBankSharingTariffs).map((country) => (
              <option key={country} value={country}>
                {country}
              </option>
            ))}
        </select>
        {selectedCountry &&
          (powerBankSharingTariffs[selectedCountry]?.length > 0 ? (
            powerBankSharingTariffs[selectedCountry].map(({name, price, text}, index,array) => (
              <>
                {isValidData({name, price, text}) ? (
                  <>
                    <div className={css.item}>
                      <div className={css.firstRowContainer}>
                        <p>{name}</p>
                        <p>{price}</p>
                      </div>
                      <p>{text}</p>
                    </div>
                    {renderDottedLine(index, array.length)}
                  </>
                ) : (
                  <p>
                    Coming soon ...
                  </p>
                )}
              </>
            ))
          ) : (
            ''
          ))}
      </div>
    </div>
  );
};

export default ChargerTariffs;