import React from 'react';
import img1 from "../../../assets/images/easyToUse/frame-1.png"
import img2 from "../../../assets/images/easyToUse/Frame (1).png"
import img3 from "../../../assets/images/easyToUse/Frame.png"
import img4 from "../../../assets/images/easyToUse/Frame (3).png"
import css from "./easyToUse.module.scss"
import { isMobile } from 'react-device-detect';
import {useTranslation} from "react-i18next";

const EasyToUse = () => {
  const { t } = useTranslation();
  const classes = css.easyToUseContainer + (isMobile ? ' ' + css.mobileEasyToUseContainer : '');

  return (
    <div className={classes}>
      <div className={css.titleContainer} >
        <h1>{t('easyToUse')}</h1>
        <p>
          {t('easyToUseText')}
        </p>
      </div>
      <div className={css.itemContainerRight}>
        <img src={img1} alt=""/>
        <div className={css.informationContainer}>
          <div className={css.orderContainer}>1</div>
          <div className={css.subInformationContainer} >
            <h3>{t('easyToUseItem1Title')}</h3>
            <p>{t('easyToUseItem1Text')}</p>
          </div>
        </div>
      </div>
      <div className={css.itemContainerLeft}>
        <div className={css.informationContainer}>
          <div className={css.orderContainer}>2</div>
          <div className={css.subInformationContainer}>
            <h3>{t('easyToUseItem2Title')}</h3>
            <p>
              {t('easyToUseItem2Text')}
            </p>
          </div>
        </div>
        <img src={img2} alt=""/>
      </div>
      <div className={css.itemContainerRight} >
        <img src={img3} alt=""/>
        <div className={css.informationContainer}>
          <div className={css.orderContainer}>3</div>
          <div className={css.subInformationContainer}>
            <h3>{t('easyToUseItem3Title')} </h3>
            <p>
              {t('easyToUseItem3Text')}
            </p>
          </div>
        </div>
      </div>
      <div className={css.itemContainerLeft}>
        <div className={css.informationContainer}>
          <div className={css.orderContainer}>4</div>
          <div className={css.subInformationContainer}>
            <h3>{t('easyToUseItem4Title')}</h3>
            <p>
              {t('easyToUseItem4Text')}
            </p>
          </div>
        </div>
        <img src={img4} alt=""/>
      </div>
    </div>
  );
};

export default EasyToUse;