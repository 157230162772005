import React, {useEffect, useState} from 'react';
import { isMobile } from 'react-device-detect';
import { IoCloseSharp } from 'react-icons/io5';

import Modal from '../../../components/modal';
import ModalForm from '../../../components/modalForm';

import img1 from '../../../assets/images/franchiseCalculator/first.png';
import img2 from '../../../assets/images/franchiseCalculator/second.png';
import img3 from '../../../assets/images/franchiseCalculator/third.png';
import img4 from '../../../assets/images/franchiseCalculator/Rectangle 27383 (1).png';

import css from './franchiseCalculator.module.scss';
import {useTranslation} from "react-i18next";

const FranchiseCalculator = () => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const [selectedButton, setSelectedButton] = useState('Power Bank');
  const [calculator,setCalculator] = useState('')
  const [profit,setProfit] = useState('')

  const handleOpen = () => {
    window.scroll(0, 0);
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleButtonClick = (button) => {
    setSelectedButton(button);
    setProfit('')
    setCalculator('')
  };
  const handleChange = (e)=>{
    setCalculator(e.target.value)
  }
useEffect(()=>{
if(selectedButton==='Power Bank'&& calculator>=10000){
  setProfit(+calculator+(+calculator*8/100))
}
if(selectedButton==='Scooter'&&calculator>=30000){
  setProfit(+calculator+(+calculator*10.5/100))
}
if(selectedButton==='Power Bank' && calculator<10000){
  setProfit(0)
}
  if(selectedButton==='Scooter' && calculator<30000){
    setProfit(0)
  }
},[selectedButton,calculator,profit])


  const classes = css[
    isMobile ? 'mobileFranchiseCalculatorContainer' : 'franchiseCalculatorContainer'
    ];

  return (
    <div className={classes}>
      {openModal ? (
        <Modal>
          <div className={css.sendFormModal}>
            <div className={css.modalTitle}>
              <div className={css.closeContainer}>
                <IoCloseSharp onClick={handleClose}/>
              </div>
              <h2>Receive the Franchise Calculator</h2>
            </div>
            <ModalForm/>
          </div>
        </Modal>
      ) : (
        ''
      )}
      <div className={css.calculatorInfo}>
        <h1>{t('franchiseCalculator')}</h1>
        <div className={css.item}>
          <img src={img1} alt="" />
          <p>{t('franchiseCalculatorItem1text')}</p>
        </div>
        <div className={css.item}>
          <img src={img2} alt="" />
          <p>{t('franchiseCalculatorItem2text')}</p>
        </div>
        <div className={css.item}>
          <img src={img3} alt="" />
          <p>{t('franchiseCalculatorItem3text')}</p>
        </div>
      </div>
      <div className={css.sendForm}>
        <div className={css.imgContainer}>
          <img src={img4}  />
        </div>
        <div className={css.formContainer}>
          <div className={css.buttonGroup}>
            <button
              className={selectedButton === 'Power Bank' ? css.selected : ''}
              onClick={() => handleButtonClick('Power Bank')}
            >
              {t('powerBank')}
            </button>
            <button
              className={selectedButton === 'Scooter' ? css.selected : ''}
              onClick={() => handleButtonClick('Scooter')}
            >
              {t('scooter')}
            </button>

          </div>
          <div className={css.formTitle}>
            <h3>
              {t('franchiseCalculatorText')}
            </h3>
          </div>
          <div className={css.form}>
            <label >
              {t('investmentAmount')}
              <input
                type="text"
                placeholder={t('investmentAmountPlaceholder')}
                value={calculator}
                className={css.input1}
                onChange={(e)=>{handleChange(e)}}/>
            </label>
            <label >
              {t('investmentAmount')}
              <input
                placeholder='0 AMD'
                type="text"
                value={profit}
              className={css.input2}
              />
            </label>
            <button onClick={handleOpen}>
              {t('ReceiveTheFranchiseCalculator')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FranchiseCalculator;
