import axios from "axios";
import requests from "../../services";

export const getDataThunk = ()=>async (dispatch)=>{

  try{
    const response = await axios.get(requests?.data)

    dispatch({
      type:'GET_MIMO_DATA',
      payload:response.data
    })

  }
  catch (e){
    console.log(e)
  }
}