import React, {useState} from 'react';
import css from './contactUs.module.scss'

import {isMobile} from "react-device-detect";
import {useDispatch} from "react-redux";

import {useTranslation} from "react-i18next";
import {becomePartnerThunk} from "../../../redux/action/becomePartner";

const ContactUs = ({img}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [inputValues, setInputValues] = useState({
    name: '',
    email: '',
    country: '',
    phone: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputValues({ ...inputValues, [name]: value });
  };
  const handleAdd =() =>{
    const { name, email, country, phone } = inputValues;

    if (name && email && country && phone) {

      dispatch(becomePartnerThunk(inputValues)).then(  dispatch({
        type:'LOADING'
      })).then(_=>{
        setInputValues({
          name: '',
          email: '',
          phone: '',
          country: '',
        });
      })
    }
  }
  const classes = css[isMobile ? 'mobileContainer' : 'container'];
  return (
    <div className={classes}>
      <div className={css.subContainer}>
        <div className={css.section}>
          <div className={css.imgContainer}>
            <img src={img} alt=""/>
          </div>
        <div className={css.contactUsForm} >
          <div className={css.titleContainer}>
            <h1>{t(`contactUs`)}</h1>
            <p>{t(`contactUsTitle`)}</p>
          </div>
          <div className={css.form}>
            <div className={css.sendForm}>
              <div className={css.rows}>
                <div className={css.firstRowItems}>
                  <input
                    type="text"
                    name='name'
                    value={inputValues.name}
                    onChange={handleInputChange}
                    placeholder='Your Name'/>
                  <input
                    type="text"
                    name='country'
                    value={inputValues.country}
                    onChange={handleInputChange}
                    placeholder={t(`Country`)}/>
                </div>
                <div className={css.rowItems}>
                  <input
                    type="text"
                    name='phone'
                    value={inputValues.phone}
                    onChange={handleInputChange}
                    placeholder={t(`Your Phone`)}/>
                  <input
                    type="text"
                    name='email'
                    value={inputValues.email}
                    onChange={handleInputChange}
                    placeholder={t(`Email`)}
                  />
                  <div className={css.btnContainer}>
                    <button onClick={handleAdd}>{t(`contact`)} </button>
                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;