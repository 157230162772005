import axios from "axios";
import {toast} from "react-toastify";
import requests from "../../services";

export const subscribeThunk = (data)=>async (dispatch)=>{
  try{
    const response = await axios.post(requests.subscribe,data)
    dispatch({
      type:'SUBSCRIBE',
      payload:response.data
    })
    toast.success('Your submission has been successful.', {
      position: "top-right",
      autoClose: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      duration: 5000,
      theme: "light",
    });
  }
  catch (e){
    console.log(e)
    toast.error('Your submission has been  failed', {
      position: "top-right",
      autoClose: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      duration: 5000,
      theme: "light",
    });


  }
}