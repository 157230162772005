import React, {useState} from 'react';
import firstItemImg from "../../../assets/images/grownYourBusinessWithMimo/first.png"
import secondItemImg from "../../../assets/images/grownYourBusinessWithMimo/second.png"
import thirdItemImg from "../../../assets/images/grownYourBusinessWithMimo/third.png"
import fourthItemImg from "../../../assets/images/grownYourBusinessWithMimo/fourth.png"
import css from './grownYourBusinessWithMimo.module.scss'
import {isMobile} from "react-device-detect";
import {useTranslation} from "react-i18next";
import Modal from "../../../components/modal";
import {IoCloseSharp} from "react-icons/io5";
import ModalForm from "../../../components/modalForm";

const GrownYourBusinessWithMimo = () => {
  const { t } = useTranslation();

  const[openModal,setOpenModal] = useState(false)
  const handleClose = ()=>{
    setOpenModal(false)
  }




  const classes = css[isMobile ? 'mobileContainer' : 'container'];

  return (
    <div className={classes}>
      <div className={css.titleContainer}>
        <h1>{t('growYourBusinessWithMimo')}</h1>
      </div>
      <div className={css.items}>
        <div className={css.item}>
          <img src={firstItemImg} alt=""/>
          <h4>{t('growYourBusinessWithMimoItem1')}</h4>
          <p>{t('growYourBusinessWithMimoItem1text')}</p>

        </div>
        <div className={css.item}>
          <img src={secondItemImg} alt=""/>
          <h4>{t('growYourBusinessWithMimoItem2')}</h4>
          <p> {t('growYourBusinessWithMimoItem2text')}</p>
        </div>
        <div className={css.item}>
          <img src={thirdItemImg} />
          <h4>{t('growYourBusinessWithMimoItem3')}</h4>
          <p>{t('growYourBusinessWithMimoItem3text')}</p>
        </div>
        <div className={css.item}>
          <img src={fourthItemImg} />
          <h4>{t('growYourBusinessWithMimoItem4')}</h4>
          <p>{t('growYourBusinessWithMimoItem4text')}</p>
        </div>
      </div>
      <div className={css.btnContainer}>
        <button onClick={()=>{setOpenModal(true)}}>{t('getABusinessPlan')}</button>
      </div>
      {
        openModal ? <Modal>
          <div className={css.sendFormModal}>
            <div className={css.modalTitle}>
              <div className={css.closeContainer}>
                <IoCloseSharp onClick={handleClose}/>
              </div>
              <h2>Get a business plan</h2>
            </div>
            <ModalForm/>
          </div>
        </Modal> : ''
      }
    </div>
  );
};

export default GrownYourBusinessWithMimo;

