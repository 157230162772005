import React from 'react';
import img from '../../../assets/images/franchise/IMG_9226.PNG'
import img1 from '../../../assets/images/franchise/first.png'
import img2 from '../../../assets/images/franchise/seocnd.png'
import img3 from '../../../assets/images/franchise/third.png'
import img4 from '../../../assets/images/franchise/fourth.png'
import css from "./franchise.module.scss"
import {isMobile} from "react-device-detect";
import {useTranslation} from "react-i18next";

const Franchise = () => {
  const { t } = useTranslation();
  const classes = css[isMobile ? 'mobileContainer' : 'container'];
  return (
    <div className={classes}>
      <h1>{t('whatIsIncludedInTheFranchise')}</h1>
      <div className={css.items}>
        <div className={css.item}>
          <img src={img1}/>
          <h4>1. {t('whatIsIncludedInTheFranchiseItem1Title')}</h4>
            <p>{t('Business Roadmap')} <br/>
              {t('Launch Guidelines')}<br/>
              {t('Administrative Aid')}<br/>
              {t('Legal Troubleshooting')}<br/>
              {t('Staff Training')}<br/>
              {t('Tax Advisory')}
            </p>
        </div>
        <div className={css.item}>
          <img src={img2} alt=""/>
          <h4>2. {t('whatIsIncludedInTheFranchiseItem2Title')}</h4>
          <p> {t('Electric Scooters')} <br/>
            {t('Bicycles')}<br/>
            {t('Charging Stations')}<br/>
            {t('Chargers')}<br/>
            {t('Spare Parts')}<br/>
            {t('Accessories')}</p>
        </div>
        <div className={css.item}>
          <img src={img3}alt=""/>
          <h4>3. {t('whatIsIncludedInTheFranchiseItem3Title')}</h4>
          <p >{t('Administrative CRM System')} <br/>
            {t('Service Application for Technicians')}<br/>
            {t('Legal Support')}<br/>
            {t('iOS Mobile App')}<br/>
            {t('Android Mobile App')}</p>
        </div>
        <div className={css.item}>
          <img src={img4} alt=""/>
          <h4>4. {t('whatIsIncludedInTheFranchiseItem4Title')}</h4>
          <p>{t('24/7 Partner Assistance')}<br/>
            {t('Dispatch Support Organization')}<br/>
            {t('Post-Sales Services')}<br/>
            {t('Legal Assistance')}<br/>
            {t('Financial Consultation')}<br/>
            {t('Marketing Support')}</p>
        </div>
      </div>
    </div>
  );
};

export default Franchise;