import React, {useState, useEffect} from 'react';
import css from './bikeTariffs.module.scss';
import {isMobile} from 'react-device-detect';

const BikeTariffs = ({bikeSharingTariffs}) => {

  const [selectedCountry, setSelectedCountry] = useState('');

  useEffect(() => {
    if (bikeSharingTariffs && Object.keys(bikeSharingTariffs).length > 0) {
      setSelectedCountry(Object.keys(bikeSharingTariffs)[0]);
    }
  }, [bikeSharingTariffs]);

  const handleCountryChange = (e) => {
    setSelectedCountry(e.target.value);
  };

  const isValidData = (data) => {
    return (
      data?.name !== '' &&
      data?.price !== '' &&
      data?.text !== '' &&
      data?.price !== 0
    );
  };

  const classes = css[isMobile ? 'mobileContainer' : 'container'];

  return (
    <div className={classes}>

      <h1>Bike Tariffs</h1>
      <select value={selectedCountry} onChange={handleCountryChange}>
        {bikeSharingTariffs &&
          Object.keys(bikeSharingTariffs).map((country) => (
            <option key={country} value={country}>
              {country}
            </option>
          ))}
      </select>
      <div className={css.items}>
        {selectedCountry &&
          (bikeSharingTariffs[selectedCountry]?.length > 0 ? (
            bikeSharingTariffs[selectedCountry].map(({name, price, text}, index) => (
              <>
                {isValidData({name, price, text}) ? (
                  <div className={css.item} >
                    <div className={css.numberContainer}>{name}</div>
                    <h3>{text}</h3>
                    <p>{price}</p>
                  </div>
                ) : (
                  <p>
                    Coming soon...
                  </p>
                )}
              </>
            ))
          ) : (
            <p>''</p>
          ))}
      </div>
    </div>
  );
};

export default BikeTariffs;

