import React from 'react';
import img1 from '../../../assets/images/whyChoosMimo/first.png'
import img2 from '../../../assets/images/whyChoosMimo/second.png'
import img3 from '../../../assets/images/whyChoosMimo/third.png'

import css from './whyChooseMimo.module.scss'
import {isMobile} from "react-device-detect";
import {useTranslation} from "react-i18next";

const WhyChooseMimo = () => {
  const { t } = useTranslation();
  const classes = css[isMobile ? 'mobileWhyChooseMimoContainer' : 'whyChooseMimoContainer'];

  return (
    <div className={classes}>
      <div className={css.titleContainer}>
        <h1>{t('whyChooseMimo')}</h1>
      </div>
      <div className={css.items}>
        <div className={css.item}>
          <div className={css.itemImg}>
            <img  src={img1} className={css.img} />
          </div>
          <div  className={css.itemInformation}>
            <div  className={css.subItemInfo} >
              1
              <div className={css.subItemTitle}>
                <h4>{t('whyChooseMimoItem1Title')}</h4>
              </div>
            </div>
            <ul >
                <li>{t('whyChooseMimoItem1Li1')}</li>
                <li>{t('whyChooseMimoItem1Li2')}</li>
                <li>{t('whyChooseMimoItem1Li3')}</li>
                <li>{t('whyChooseMimoItem1Li4')}</li>
              </ul>

          </div>
        </div>
        <div className={css.item}>
          <div className={css.itemImg}>
            <img  src={img2} className={css.img} alt=""/>
          </div>
          <div  className={css.itemInformation}>
            <div  className={css.subItemInfo} >
              2
              <div className={css.subItemTitle}>
                <h4> {t('whyChooseMimoItem2Title')}</h4>
              </div>
            </div>
            <div>
              <ul >
                <li>{t('whyChooseMimoItem2Li1')}</li>
                <li>{t('whyChooseMimoItem2Li2')}</li>
                <li>{t('whyChooseMimoItem2Li3')}</li>
              </ul>
            </div>
          </div>
        </div>
        <div className={css.item}>
          <div className={css.itemImg}>
            <img className={css.img} src={img3} alt=""/>
          </div>
          <div  className={css.itemInformation}>
            <div  className={css.subItemInfo} >
              3
              <div className={css.subItemTitle}>
                <h4>{t('whyChooseMimoItem3Title')}</h4>
              </div>
            </div>
            <div>
              <ul >
                <li>{t('whyChooseMimoItem3Li1')}</li>
                <li>{t('whyChooseMimoItem3Li2')}</li>
                <li>{t('whyChooseMimoItem3Li3')}</li>
                <li>{t('whyChooseMimoItem3Li4')}</li>
                <li>{t('whyChooseMimoItem3Li5')}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

export default WhyChooseMimo;