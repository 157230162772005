import AboutMimo from "./aboutMimo";
import EasyToUse from "./easyToUse";
import YourRidingBuddy from "./yourRidingBuddy";
import DownloadOurApp from "./downloadOurApp";
import PartnerWithMimo from "./partnerWithMimo";
import WhatOurRidersSay from "./whatOurRidersSay";

import css from "./home.module.scss"


const Homepage = () => {


  return (
    <div className={css.homePage}>
      <AboutMimo/>
      <EasyToUse/>
      <YourRidingBuddy/>
      <DownloadOurApp/>
      <PartnerWithMimo/>
      <WhatOurRidersSay/>
    </div>
  );
};

export default Homepage;