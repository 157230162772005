import Menu from "../../pages/menu";

import css from "./header.module.scss"

const Header = () => {

  return (
    <div className={css.headerContainer} >
      <Menu/>
    </div>
  );
};

export default Header;