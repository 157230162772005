import { useNavigate } from "react-router-dom";
import { FaTimes } from "react-icons/fa";

import {
  BECOME_A_PARTNER,
  BIKE_SHARING,
  CONTACT_PAGE,
  HOME_PAGE,
  POWER_BANK_SHARING,
  SCOOTER_SHARING,
} from "../../utils/url";

import Logo from "../../pages/logo";

import css from "./mobileNavigation.module.scss";

const items = [
  {
    name: "About Mimo",
    value: HOME_PAGE,
  },
  {
    name: "Bike Sharing",
    value: BIKE_SHARING,
  },
  {
    name: "Scooter Sharing",
    value: SCOOTER_SHARING,
  },
  {
    name: "Power Bank Sharing",
    value: POWER_BANK_SHARING,
  },
  {
    name: "Become a Partner",
    value: BECOME_A_PARTNER,
  },
  {
    name: "Contact",
    value: CONTACT_PAGE,
  },
];

export const MobileNavigation = ({ onClose }) => {
  const navigateTo = useNavigate();

  const handleNavigation = (to) => {
    navigateTo(to);
    !!onClose && onClose();
  };

  return (
    <div className={css.mobileNavigation}>
      <div className={css.header}>
        <Logo />
        <FaTimes onClick={onClose} />
      </div>
      {items.map(({ name, value }) => (
        <div onClick={() => handleNavigation(value)} className={css.gjh}>
          {name}
        </div>
      ))}
    </div>
  );
};
