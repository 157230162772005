import React from 'react';
import img1 from '../../../../src/assets/images/electronicDevices/smartphone.png'
import img2 from '../../../../src/assets/images/electronicDevices/tablet.png'
import img3 from '../../../../src/assets/images/electronicDevices/sigaret.png'
import img4 from '../../../../src/assets/images/electronicDevices/earphones.png'
import img5 from '../../../../src/assets/images/electronicDevices/kindle.png'
import css from "./electronicDevices.module.scss"
import {isMobile} from "react-device-detect";
import {useTranslation} from "react-i18next";
const ElectronicDevices = () => {
  const { t } = useTranslation();
  const classes = css[isMobile?'mobileContainer':'container']
  return (
    <div className={classes}>
      <h1>{t('builtInTitle')}</h1>
      <div className={css.items} >
        <div className={css.item}>
          <img src={img1} />
          <p>{t('builtInTitleItem1')}</p>
        </div>
        <div className={css.item}>
          <img src={img2} />
          <p>{t('builtInTitleItem2')}</p>
        </div>
        <div className={css.item}>
          <img src={img3}/>
          <p>{t('builtInTitleItem3')}</p>
        </div>
        <div className={css.item}>
          <img src={img4} />
          <p>{t('builtInTitleItem4')}</p>
        </div>
        <div className={css.item}>
          <img src={img5} />
          <p>{t('builtInTitleItem5')}</p>
        </div>
      </div>
      
    </div>
  );
};

export default ElectronicDevices;