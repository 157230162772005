import React, { useState, useRef } from 'react';
import css from './video.module.scss'
import video from "../../../assets/viedos/MIMO Bike Sharing.mp4"

const VideoComponent = () => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const togglePlay = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
      setIsPlaying(true);
    } else {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  return (
    <div className={css.container} >
      <video
        ref={videoRef}
        controls
        onClick={togglePlay}
        className={css.video}
      >
        <source src={video} type="video/mp4" />
      </video>
    </div>
  );
};

export default VideoComponent;


