import css from "./scooterSharing.module.scss"
import FeelTheFreedom from "./feelTheFreedom";
import DownloadOurApp from "../home/downloadOurApp";
import Video from "../powerBankSharing/video";
import ContactUs from "../powerBankSharing/contactUs";
import ChooseMimo from "./chooseMimo";
import ScooterTariffs from "./scooterTariffs";
import img from '../../assets/images/earnMoney/scooter.png'
import HowToRentscotering from "./howToRentscotering";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {getDataThunk} from "../../redux/action/data";

const ScooterSharing = () => {

  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.mimoDataReducer);

  const scooterSharingTariffs = data?.data?.scooterSharingTariffs;


  useEffect(() => {
    dispatch(getDataThunk());
  }, []);

  return (
    <div className={css.container}>
      <FeelTheFreedom/>
      <HowToRentscotering/>
      <DownloadOurApp/>
      {/*<Video/>*/}
      <ChooseMimo/>
      <ScooterTariffs scooterSharingTariffs={scooterSharingTariffs}/>
      <ContactUs img={img}/>
    </div>
  );
};

export default ScooterSharing;