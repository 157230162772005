import React from 'react';
import css from './becomeAPartner.module.scss'
import JoinOurFranchise from "./joinOurFranchise";
import WhyChooseMimo from "./whyChooseMimo";
import GrownYourBusinessWithMimo from "./grownYourBussinesWithMimo";
import FranchiseCalculator from "./franchiseCalculator";
import HowToBecomePartner from "./howToBecomePartner";
import Franchise from "./franchise";
import ApplicationAndServicePrograms from "./applicationAndServicePrograms";
import ExpectationsFromPartners from "./expectationsFromPartners";
import Equipment from "./equipment";
import EarnMoney from "./earnMoney";
import img from '../../assets/images/earnMoney/Contact.png';


const BecomeAPartner = () => {

  return (
    <div className={css.container} >
      <JoinOurFranchise/>
      <GrownYourBusinessWithMimo/>
      <WhyChooseMimo/>
      <FranchiseCalculator/>
      <HowToBecomePartner/>
      <Franchise/>
      <ApplicationAndServicePrograms/>
      <ExpectationsFromPartners/>
      <Equipment/>
      <EarnMoney img={img}/>

    </div>
  );
};

export default BecomeAPartner;