import css from "./logo.module.scss";
import logo from "../../assets/images/logo/Logo.png";
const Logo = () => {
  return (

    <div className={css.logoContainer}>
      <img src={logo} alt=""/>
    </div>

  );
};

export default Logo;
