import css from "./App.module.scss"
import Layout from "./layout";
import {isMobile} from "react-device-detect";
import {useEffect} from "react";
import {useTranslation} from "react-i18next";


function App() {
  const { t, i18n } = useTranslation();
  useEffect((e)=>{
    i18n.changeLanguage('en')

  },[])
  const classes = css[isMobile ? 'mobile' : 'desktop'];

  return (
    <div className={classes}>
      <Layout/>
    </div>
  );
}

export default App;
