import React, {useEffect, useState} from 'react';
import css from './chargerPlans.module.scss'
import {isMobile} from "react-device-detect";
import {useTranslation} from "react-i18next";

const ChargerPlans = ({powerBankSharingPlansData}) => {
  const { t } = useTranslation()
  const [selectedCountry, setSelectedCountry] = useState('');

  useEffect(() => {
    if (powerBankSharingPlansData && Object.keys(powerBankSharingPlansData).length > 0) {
      setSelectedCountry(Object.keys(powerBankSharingPlansData)[0]);
    }
  }, [powerBankSharingPlansData]);

  const handleCountryChange = (e) => {
    setSelectedCountry(e.target.value);
  };
  const renderDottedLine = (index, totalItems) => {
    return index !== totalItems - 1 ? <div className={css.borderDotted}></div> : null;
  };
  const isValidData = (data) => {
    return (
      data?.name !== '' &&
      data?.price !== '' &&
      data?.text !== '' &&
      data?.price !== 0
    );
  };

  const classes = css[isMobile ? `mobileChargerPlansContainer` : 'chargerPlansContainer']
  return (
    <div className={classes}>
      <div className={css.section1}>
        <select value={selectedCountry} onChange={handleCountryChange}>
          {powerBankSharingPlansData &&
            Object.keys(powerBankSharingPlansData).map((country) => (
              <option key={country} value={country}>
                {country}
              </option>
            ))}
        </select>
        {selectedCountry &&
          (powerBankSharingPlansData[selectedCountry]?.length > 0 ? (
            powerBankSharingPlansData[selectedCountry].map(({name, price, text}, index, array) => (
              <>
                {isValidData({name, price, text}) ? (
                  <>
                    <div className={css.item}>
                      <div className={css.firstRowContainer}>
                        <p>{name}</p>
                        <p>{price}</p>
                      </div>
                      <p>{text}</p>
                    </div>
                    {renderDottedLine(index, array.length)}
                  </>
                ) : (
                  <p>
                    Coming soon...
                  </p>
                )}
              </>
            ))
          ) : (
            ''
          ))}

      </div>
      <div className={css.section2}>
        <h1>{t(`chargerPlans`)}</h1>
        <p> {t(`chargerPlansText`)}</p>

      </div>
    </div>
  );
};

export default ChargerPlans;