import React, {useState} from 'react';
import css from "./modalForm.module.scss"
import {isMobile} from "react-device-detect";
import {useDispatch} from "react-redux";
import {becomePartnerThunk} from "../../redux/action/becomePartner";


const ModalForm = () => {
const dispatch = useDispatch()
  const[openModal,setOpenModal] = useState(false)

  const [inputValues, setInputValues] = useState({
    fullName: '',
    email: '',
    location: '',
    phone: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputValues({ ...inputValues, [name]: value });
  };
  // const handleClose = ()=>{
  //   setOpenModal(false)
  // }
  const handleSend=() =>{
    const { fullName, email, location, phone } = inputValues;

    if (fullName && email && location && phone) {
    
      dispatch(becomePartnerThunk(inputValues)).then(
        setInputValues({
          fullName: '',
          email: '',
          location: '',
          phone: '',
        })
      )
    }
  }
  const classes = css[
    isMobile ? 'mobileFormContainer' : 'formContainer'
    ];
  return (
    <div className={classes} >
      <div className={css.rows}>
        <div className={css.firstRowItems}>
          <input onChange={handleInputChange} name='fullName' value={inputValues.fullName} type="text" placeholder='Your Name'/>
          <input onChange={handleInputChange} name='location' value={inputValues.location} type="text" placeholder='Country'/>
        </div>
        <div className={css.rowItems}>
          <input onChange={handleInputChange} name='phone' value={inputValues.phone} type="text" placeholder="Phone Number"/>
          <input onChange={handleInputChange} name='email' value={inputValues.email} type="text" placeholder="Email"/>
        </div>
      </div>
      <button onClick={handleSend}>Send</button>
    </div>
  );
};

export default ModalForm;