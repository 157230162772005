import React from 'react';
import img1 from '../../../../src/assets/images/popularLocations/Cafe.png'
import img2 from '../../../../src/assets/images/popularLocations/Gym.png'
import img3 from '../../../../src/assets/images/popularLocations/Airport.png'
import img4 from '../../../../src/assets/images/popularLocations/Shopping center.png'
import img5 from '../../../../src/assets/images/popularLocations/Bar.png'
import img6 from '../../../../src/assets/images/popularLocations/University.png'
import img7 from '../../../../src/assets/images/popularLocations/Restaurant.png'
import img8 from '../../../../src/assets/images/popularLocations/Festival.png'
import img9 from '../../../../src/assets/images/popularLocations/Hospital.png'
import css from "./popularLocations.module.scss"
import {isMobile} from "react-device-detect";
import {useTranslation} from "react-i18next";

const PopularLocations = () => {
  const { t } = useTranslation()
  const classes = css[isMobile ? 'mobileContainer' : 'container']
  return (
    <div className={classes}>
      <div className={css.titleContainer}>
        <h1>
          {
            t(`popularLocations`)
          }
        </h1>
      </div>
      <div className={css.items}>
        <div className={css.firstRow}>
          <div className={css.item}>
            <img src={img1} alt=""/>
            <p>  {t(`popularLocationsItem1`)}</p>
          </div>
          <div className={css.item}>
            <img src={img2} alt="" />
            <p>{t(`popularLocationsItem2`)}</p>
          </div>
          <div className={css.item}>
            <img src={img3} alt="" />
            <p>{t(`popularLocationsItem3`)}</p>
          </div>
          <div className={css.item}>
            <img src={img4} alt="" />
            <p>{t(`popularLocationsItem4`)}</p>
          </div>
          <div className={css.item}>
            <img src={img5} alt="" />
            <p>{t(`popularLocationsItem5`)}</p>
          </div>
        </div>
        <div className={css.secondRow}>
          <div className={css.item}>
            <img src={img6} alt="" />
            <p>{t(`popularLocationsItem6`)}</p>
          </div>
          <div className={css.item}>
            <img src={img7} alt="" />
            <p>{t(`popularLocationsItem7`)}</p>
          </div>
          <div className={css.item}>
            <img src={img8} alt="" />
            <p>{t(`popularLocationsItem8`)}</p>
          </div>
          <div className={css.item}>
            <img src={img9} alt=""/>
            <p>{t(`popularLocationsItem9`)} </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopularLocations;