import React from 'react';
import css from './howToRent.module.scss';
import img1 from "../../../assets/images/easyToUse/chargerr.png";
import img2 from "../../../assets/images/easyToUse/Frame.png";
import img3 from "../../../assets/images/easyToUse/Illustration (1).png";
import img4 from "../../../assets/images/easyToUse/Illustration (2).png";
import {isMobile} from "react-device-detect";
import {useTranslation} from "react-i18next";

const HowToRent = () => {
  const { t } = useTranslation();
  const classes = css[isMobile ? 'mobileContainer' : 'container'];
  return (
    <div className={classes}>
      <div className={css.titleContainer}>
        <h1>{t('howToRent')}</h1>
        <p>{t('howToRentText')}</p>
      </div>
      <div className={css.leftItemContainer}>
        <img src={img1} style={{width:350}} />
        <div className={css.informationContainer}>
          <div className={css.orderContainer}>1</div>
          <div className={css.subInformationContainer}>
            <h3>{t('howToRentTextItem1Title')}</h3>
            <p>{t('howToRentTextItem1Text')}</p></div>
        </div>
      </div>
      <div className={css.rightContainer}>
        <div className={css.informationContainer}>
          <div className={css.orderContainer}>2</div>
          <div className={css.subInformationContainer}>
            <h3>{t('howToRentTextItem2Title')}</h3>
            <p>
              {t('howToRentTextItem2Text')}
            </p>
          </div>
        </div>
        <img src={img2} alt=""/>
      </div>
      <div className={css.leftItemContainer}>
        <img src={img3} />
        <div className={css.informationContainer}>
          <div className={css.orderContainer}>3</div>
          <div className={css.subInformationContainer}>
            <h3>
              {t('howToRentTextItem3Title')}
            </h3>
            <p>
              {t('howToRentTextItem3Text')}
            </p>
          </div>
        </div>
      </div>
      <div className={css.rightContainer}>
        <div className={css.informationContainer}>
          <div className={css.orderContainer}>4</div>
          <div className={css.subInformationContainer}>
            <h3>{t('howToRentTextItem4Title')}</h3>
            <p>
              {t('howToRentTextItem4Text')}
            </p>
          </div>
        </div>
        <img src={img4} />
      </div>
    </div>
  );
};

export default HowToRent;