
import ChangeYourDevice from "./changeYourDevice";
import HowToRent from "./howToRent";
import Video from "./video";
import ElectronicDevices from "./electronicDevices";
import ForUser from "./forUser";
import ChargerTariffs from "./chargerTariffs";
import ChargerPlans from "./chargerPlans";
import ForMerchants from "./forMerchants";
import PopularLocations from "./popularLocations";
import ContactUs from "./contactUs";
import css from './powerBankSharing.module.scss'

import img from "../../assets/images/earnMoney/Contact.png";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {getDataThunk} from "../../redux/action/data";


const PowerBankSharing = () => {

  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.mimoDataReducer);

  const powerBankSharingTariffs = data?.data?.powerBankSharingTariffs
  const powerBankSharingPlansData = data?.data?.powerBankSharingPlansData



  useEffect(() => {
    dispatch(getDataThunk());
  }, []);

  return (
    <div className={css.container} >
      <ChangeYourDevice/>
      <HowToRent/>
      {/*<Video/>*/}
      <ElectronicDevices/>
      <ForUser/>
      <ChargerTariffs powerBankSharingTariffs={powerBankSharingTariffs} />
      <ChargerPlans powerBankSharingPlansData={powerBankSharingPlansData}/>
      <ForMerchants/>
      <PopularLocations/>
      <ContactUs img={img}/>
    </div>
  );
};

export default PowerBankSharing;