import React from 'react';
import css from './expectationsFromPartners.module.scss'
import firstItemImg from "../../../assets/images/ExpectationsFromPartners/first.png";
import secondItemImg from "../../../assets/images/ExpectationsFromPartners/second.png";
import thirdItemImg from "../../../assets/images/ExpectationsFromPartners/third.png";
import {isMobile} from "react-device-detect";
import {useTranslation} from "react-i18next";


const ExpectationsFromPartners = () => {
  const { t } = useTranslation();
  const classes = css[isMobile ? 'mobileContainer' : 'container'];
  return (
    <div className={classes}>
      <div className={css.titleContainer}>
        <h1>
          {t('What Do We Expect From Our Partners?')}
        </h1>
      </div>
      <div className={css.items}>
        <div className={css.item}>
          <img src={firstItemImg} alt=""/>
          <h4>
            {t('whatDoWeExpectFromOurPartnersItem1Title')}
          </h4>
          <p>
            {t('whatDoWeExpectFromOurPartnersItem1Text')}
          </p>
        </div>
        <div className={css.item}>
          <img src={secondItemImg} alt=""/>
          <h4>
            {t('whatDoWeExpectFromOurPartnersItem2Title')}
          </h4>
          <p>
            {t('whatDoWeExpectFromOurPartnersItem2Text')}
          </p>
        </div>
        <div className={css.item}>
          <img src={thirdItemImg} />
          <h4>
            {t('whatDoWeExpectFromOurPartnersItem3Title')}
          </h4>
          <p>
            {t('whatDoWeExpectFromOurPartnersItem3Text')}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ExpectationsFromPartners;