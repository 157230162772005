import React, {useEffect, useState} from 'react';
import {BsTelephone} from "react-icons/bs";
import {MdOutlineMail} from "react-icons/md";
import {CiLocationOn} from "react-icons/ci";

import css from "./contact.module.scss"
import {isMobile} from "react-device-detect";
import {useDispatch, useSelector} from "react-redux";
import {contactUsThunk} from "../../redux/action/contactUs";
import {getDataThunk} from "../../redux/action/data";
import {useTranslation} from "react-i18next";

const Contact = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation();
  const {data} = useSelector((state) => state.mimoDataReducer);
  const contactUs = data?.data?.contactUs
  const [inputValues, setInputValues] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  const handleInputChange = (e) => {
    const {name, value} = e.target;
    setInputValues({...inputValues, [name]: value});
  };
  const handleAdd = () => {
    const {name, email, subject, message} = inputValues;

    if (name && email && subject && message) {
      dispatch(contactUsThunk(inputValues)).then(dispatch({
        type: 'LOADING'
      })).then(_ => {
        setInputValues({
          name: '',
          email: '',
          subject: '',
          message: '',
        });
      })
    }
  }
  useEffect(() => {
    dispatch(getDataThunk());
  }, []);
  const classes = css[isMobile ? 'mobileContactContainer' : 'contactContainer'];
  return (
    <div className={classes}>

      <div className={css.informationContainer}>
        <h1>{t('contactTitle')}</h1>
        <p>{t('contactText')}</p>
      </div>
      <div className={css.navContainer}>
        {
          contactUs && <div className={css.navInformation}>
            <h3>{contactUs.title}</h3>
            <p>{contactUs.text}</p>
            <div><BsTelephone/> Phone
              <div>{contactUs.phone}</div>
            </div>
            <div><MdOutlineMail/> Email
              <div>{contactUs.email} </div>
            </div>
            <div><CiLocationOn/> Address
              <div> {contactUs.address}</div>
            </div>
          </div>
        }
        <div className={css.sendForm}>
          <div className={css.rows}>
            <div className={css.firstRowItems}>
              <input onChange={(e) => handleInputChange(e)} name='name' value={inputValues.name} type="text"
                     placeholder={t('namePlaceholder')}/>
              <input onChange={handleInputChange} value={inputValues.email} type="email" name='email'
                     placeholder={t('emailPlaceholder')}/>
            </div>
            <div className={css.rowItems}>
              <input onChange={handleInputChange} value={inputValues?.subject} name='subject' type="text"
                     placeholder={t('subjectPlaceholder')}/>
              <textarea onChange={handleInputChange} value={inputValues?.message} name="message" id="" cols="10"
                        placeholder={t('messagePlaceholder')} rows="6"></textarea>
            </div>
          </div>
          <button onClick={handleAdd}>{t('sendMessage')}</button>
        </div>

      </div>
    </div>
  );
};

export default Contact;