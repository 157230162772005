import React from 'react';
import css from "./chooseMimo.module.scss"
import {isMobile} from "react-device-detect";
import img from "../../../assets/images/applicationAndServicePrograms/1.png";
import svg from '../../../assets/images/forUser/Icon_checkmark.png'
import {useTranslation} from "react-i18next";

const ChooseMimo = () => {
  const { t } = useTranslation();
  const classes = css[isMobile ? 'mobileContainer' : 'container'];
  return (
    <div className={classes} >
      <div className={css.infoContainer} >
        <h1>{t(`chooseMimo`)}</h1>
        <div className={css.itemsContainer}>
          <div className={css.items} >
            <div className={css.item}>
              <img src={svg} />
              <p>{t(`chooseMimoItem1`)}</p>
            </div>
            <div className={css.item}>
              <img src={svg} />
              <p>{t(`chooseMimoItem2`)}</p>
            </div>
            <div className={css.item}>
              <img src={svg} />
              <p>{t(`chooseMimoItem3`)}</p>
            </div>
          </div>
          <div className={css.items} >
            <div className={css.item}>
              <img src={svg} />
              <p>{t(`chooseMimoItem4`)}</p>
            </div>
            <div className={css.item}>
              <img src={svg} />
              <p>{t(`chooseMimoItem5`)}</p>
            </div>
            <div className={css.item}>
              <img src={svg} />
              <p>{t(`chooseMimoItem6`)}</p>
            </div>
          </div>
        </div>

      </div>
      <div className={css.imgContainer}>
        <img src={img} alt=""/>
      </div>
    </div>
  );
};

export default ChooseMimo;