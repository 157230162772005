import {combineReducers} from "redux";
import {mimoDataReducer} from "./mimoData";
import {contactUsReducer} from "./contactUs";
import {subscribeReducer} from "./subscribe";
import {becomePartnerReducer} from "./becomePartner";


const rootReducer = combineReducers({
  mimoDataReducer,
  contactUsReducer,
  subscribeReducer,
  becomePartnerReducer
});
export default rootReducer