import React from 'react';
import img1 from "../../../assets/images/equipment/Rectangle 27435.png"
import img2 from "../../../assets/images/equipment/Rectangle 27433.png"

import css from "./equipment.module.scss"
import {isMobile} from "react-device-detect";
import {useTranslation} from "react-i18next";

const Equipment = () => {
  const { t } = useTranslation();
  const classes = css[isMobile ? 'mobileContainer' : 'container'];

  return (
    <div className={classes} >
      <h1>{t('equipment')}</h1>
  <div className={css.items}>
    <div className={css.item}>
      <img src={img1} alt=""/>
    </div>
    <div className={css.item}>
      <div><img src={img2} alt=""/></div>
      <div><img src={img2}alt=""/></div>
    </div>
    <div className={css.item}>
      <img src={img1}alt=""/>
    </div>
  </div>
    </div>
  );
};

export default Equipment;