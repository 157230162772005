import React from 'react';
import img from '../../../assets/images/applicationAndServicePrograms/1.png'
import css from  './applicationAndServicePrograms.module.scss'
import {isMobile} from "react-device-detect";
import {useTranslation} from "react-i18next";

const ApplicationAndServicePrograms = () => {
  const { t } = useTranslation();
  const classes = css[isMobile ? 'mobileContainer' : 'container'];
  return (
    <div className={classes} >
      <div className={css.infoContainer} >
        <h1>{t('applicationAndServicePrograms')}</h1>
        <p>{t('applicationAndServiceProgramsText')}</p>
        <div className={css.items} >
          <div className={css.leftItem}>
            <div className={css.item} >
              <div className={css.itemNumber}>1</div>
              <p>
                {t('applicationAndServiceProgramsItem1')}
              </p>
            </div>
            <div className={css.item} >
              <div className={css.itemNumber}>2</div>
              <p>
                {t('applicationAndServiceProgramsItem2')}
              </p>
            </div>
          </div>
          <div className={css.rightItem}>
            <div className={css.item} >
              <div  className={css.itemNumber} >3</div>
              <p>
                {t('applicationAndServiceProgramsItem3')}
              </p>
            </div>
            <div className={css.item} >
              <div className={css.itemNumber}>4</div>
              <p>
                {t('applicationAndServiceProgramsItem4')}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={css.imgContainer}>
        <img src={img} alt=""/>
      </div>
    </div>
  );
};

export default ApplicationAndServicePrograms;