import React from "react";
import { Route, Routes } from "react-router-dom";
import { AuthRoutes } from "./router";
import {HOME_PAGE} from "../utils/url";
import Logo from "./logo";


const Pages = () => {
  return (
    <Routes>
      {AuthRoutes.map(({ path, element }) => {
        return (
          <Route
            path={path}
            key={Math.random()}
            element={element}
            exact={true}
          ></Route>
        );
      })}
      <Route path={HOME_PAGE} element={<Logo/>}/>
    </Routes>
  );
};

export default Pages;
