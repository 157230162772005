import React from 'react';
import img from "../../../../src/assets/images/ForMerchants/Rectangle 27436.png"
import css from './forMerchants.module.scss'
import {isMobile} from "react-device-detect";
import {useTranslation} from "react-i18next";

const ForMerchants = () => {
  const { t } = useTranslation()
  const classes  = css[isMobile?'mobileContainer':'container']
  return (
    <div className={classes}>
      <div className={css.infoContainer}>
        <h1>
          {
            t(`forMerchantsTitle`)
          }
        </h1>
        <p>
          {
            t(`forMerchantsText`)
          }
        </p>
      </div>
      <div className={css.imgContainer}>
        <img src={img} alt=""/>
      </div>
    </div>
  );
};

export default ForMerchants;