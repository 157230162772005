import { isMobile } from "react-device-detect";

import logo from "../../../assets/images/logo/Logo.png";
import girl from "../../../assets/images/partnerWithMimo/Group (1).png";
import man from "../../../assets/images/partnerWithMimo/Group.png";
import leftPart from "../../../assets/images/partnerWithMimo/Rectangle.png";
import rightPart from "../../../assets/images/partnerWithMimo/Rectangle (1).png";

import css from "./partnerwithMimo.module.scss";
import React, {useState} from "react";
import Modal from "../../../components/modal";
import {IoCloseSharp} from "react-icons/io5";
import ModalForm from "../../../components/modalForm";
import {useTranslation} from "react-i18next";

const PartnerWithMimo = () => {
  const { t } = useTranslation();
  const[openModal,setOpenModal] = useState(false)

  const handleClose = ()=>{
    setOpenModal(false)
  }

  const classes =
    css.partnerWithMimoContainer +
    (isMobile ? " " + css.mobilePartnerWithMimoContainer : "");

  return (
    <div className={classes}>
      <div className={css.item}>
        <img src={leftPart}  />
        <div className={css.subItemImg}>
          <img src={girl}  />
        </div>
      </div>
      <div className={css.item}>
        <div className={css.itemTitle}>
          <h1>{t('partnerWith')}</h1>
          <img src={logo}  />
        </div>
        <div>
          <p>{t('partnerWithMimoText')}</p>
        </div>
        <div className={css.buttonContainer}>
          <button onClick={()=>{setOpenModal(true)}}>{t('becomeAPartner')}</button>
        </div>
      </div>
      <div className={css.item}>
        <img src={rightPart}  />
        <div className={css.subItemImg}>
          <img src={man} />
        </div>
      </div>
      {
        openModal ? <Modal>
          <div className={css.sendFormModal}>
            <div className={css.modalTitle}>
              <div className={css.closeContainer}>
                <IoCloseSharp onClick={handleClose}/>
              </div>
              <h2>Become a Partner</h2>
            </div>
            <ModalForm/>
          </div>
        </Modal> : ''
      }
    </div>
  );
};

export default PartnerWithMimo;
