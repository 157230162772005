import { isMobile } from "react-device-detect";
import { FaApple } from "react-icons/fa";

import mobileImg from "../../../assets/images/downloadApp/iPhone 15 Pro.png";
import svg2 from "../../../assets/images/changeYourDevice/svg2.png"
import svg1 from "../../../assets/images/changeYourDevice/svg1.png"

import css from "./downloadOurApp.module.scss";
import {useTranslation} from "react-i18next";

const DownloadOurApp = () => {
  const { t } = useTranslation();
  const classes =
    css.downloadOurAppContainer +
    (isMobile ? " " + css.mobileDownloadOurAppContainer : "");

  return (
    <div className={classes}>
      <div className={css.container}>
        <div className={css.informationContainer}>
          <h1>{t('downloadOurApp')}</h1>
          <p>
            {t('downloadOurAppText')}
          </p>
          <div className={css.btnContainer}>
            <div className={css.button}>
              <a href="https://apps.apple.com/am/app/mimo-meta-sharing/id1576701754" target={"_blank"}>

                <div>
                  <img src={svg1} />
                </div>
                <div>
                  <p>{t(`Download on the`)}</p>
                  <h5>App Store</h5>
                </div>
              </a>
            </div>
            <div className={css.button}>
              <a href="https://play.google.com/store/apps/details?id=app.mimo.it.MimoBike" target={"_blank"}>
                <div>
                  <img src={svg2} />
                </div>
                <div>
                  <p>{t(`Get it on`)}</p>
                  <h5>Google Play</h5>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div className={css.imageContainer}>
          <img src={mobileImg} />
        </div>
      </div>
    </div>
  );
};

export default DownloadOurApp;
