const BASE_URL = process.env.REACT_APP_BASE_URL
const PARTNERSHIP_BASE_URL = process.env.REACT_APP_PARTNERSHIP_BASE_URL


//get data
const data = BASE_URL + '/data'

//subscribe
const subscribe = PARTNERSHIP_BASE_URL + '/contact-us'
//partner
const partner = PARTNERSHIP_BASE_URL + '/partnership'
//contactus
const contactUs = PARTNERSHIP_BASE_URL + '/contact-us'

const requests = {
  data,
  partner,
  subscribe,
  contactUs

}
export default requests